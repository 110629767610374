@import 'bootstrap/dist/css/bootstrap.min.css';
@import '@fortawesome/fontawesome-free/css/all.css';
:root {
  --base-color: #085f38;
  --accent: #708f05;
  --accent2: #c8e6c9;
  --font-color: #222222;
  --font-color-dark: black;
  --bg-color: #fcfcfc;
}

.bg-success {
  background-color: var(--base-color) !important;
}

body, .sidebar, .container, .container-fluid {
  background-color: var(--bg-color);
}

.tooltip-inner {
  background-color: white;
  color: var(--font-color-dark);
  border: 1px solid var(--base-color);
}

.filter-highlighted {
  color: var(--accent) !important;
}

.filter-highlighted:hover {
  color: white !important;
}

.filter-highlighted-outline {
  color: var(--accent) !important;
}

.filter-highlighted-outline:hover {
  color: var(--base-color) !important;
}

.btn-success {
  background-color: var(--base-color);
  border-color: var(--base-color);
}

.btn-success:focus, .btn-success:active, .btn-success.active, .btn-success:active:focus, .btn-success:active {
  background-color: var(--accent) !important;
  border-color: var(--accent) !important;
  box-shadow: none !important;
  outline-color: var(--accent);
  color: white;
}

.btn-success:hover {
  background-color: var(--accent);
  border-color: var(--accent);
}

.btn-outline-success {
  color: var(--base-color);
  border-color: var(--base-color);
}

.btn-outline-success:hover, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success:focus, .btn-outline-success:active:focus {
  background-color: var(--accent) !important;
  border-color: var(--accent) !important;
  box-shadow: none !important;
  outline-color: var(--accent);
  color: white !important;
}

.my_right_aligned {
  text-align: right
}

.cardNavigation {
  cursor: pointer;
}

.ngb-dp-week-number {
  font-weight: bold;
}

.ngb-dp-showweek:before {
  content: 'KW';
}

.btn-link, .btn-link:hover, a, a:hover {
  color: var(--base-color) !important;
  text-decoration: underline;
}

.xlModal>.modal-dialog {
  max-width: 95% !important;
  min-width: 95% !important;
  width: 95% !important;
}

.toast-container .ngx-toastr {
  border: 1px solid white !important;
}

.toast-container .toast {
  margin: 2px !important;
  box-shadow: none !important;
  border-radius: 20px !important;
  background-color: #fff !important;
}

.counter {
  top: -7px !important;
  left: -1px !important;
}

.dropdown-item {
  color: var(--base-color) !important;
  text-decoration: none;
}
.dropdown-item:active {
  color: white !important;
  background-color: var(--base-color) !important;
}
/* iframe {
   display: block;       /* iframes are inline by default */

/* border: none;         Reset default border */

/* height: 95vh;        Viewport-relative units */

/* width: 100vw; */

/* } */

.wizardModal {
  width: 1300px;
}